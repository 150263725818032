div {
  position: relative;
}

.grad1 {
  width: 35vw;
  height: 35vw;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  opacity: 0.3;
  transform: translate(0vw, 0vw);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: -moz-linear-gradient(
    -45deg,
    rgba(65, 198, 206, 0.5) 50%,
    rgba(200, 153, 80, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(65, 198, 206, 0.5) 50%,
    rgba(200, 153, 80, 0.6) 100%
  );
  background: linear-gradient(135deg, rgba(65, 198, 206, 0.5) 50%, rgba(200, 153, 80, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8041c6ce', endColorstr='#99c89950', GradientType=1);
}

.grad2 {
  width: 25vw;
  height: 25vw;
  bottom: 0;
  left: 0;
  z-index: 3;
  transform: translate(0vw, 0vw);
  clip-path: polygon(0 1%, 0% 100%, 100% 100%);
  background: -moz-linear-gradient(
    -45deg,
    rgba(31, 145, 211, 0.5) 31%,
    rgba(101, 101, 101, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(31, 145, 211, 0.5) 31%,
    rgba(101, 101, 101, 0.6) 100%
  );
  background: linear-gradient(135deg, rgba(31, 145, 211, 0.5) 31%, rgba(101, 101, 101, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#801f91d3', endColorstr='#99656565', GradientType=1);
}

.gradwax1 {
  height: 70vw;
  width: 100vw;
  z-index: 3;
  transform: translate(-100vw, 0vw);
  left: 0;
  top: 0;
  opacity: 0;
  clip-path: polygon(70% 0, 100% 0, 30% 100%, 0 100%);
  background: -moz-linear-gradient(
    45deg,
    rgba(247, 143, 27, 0.5) 11%,
    rgba(101, 101, 101, 0.2) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(247, 143, 27, 0.5) 11%,
    rgba(101, 101, 101, 0.2) 100%
  );
  background: linear-gradient(45deg, rgba(247, 143, 27, 0.5) 11%, rgba(101, 101, 101, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#50f78f1b', endColorstr='#99656565', GradientType=1);
  position: absolute;
}

.gradwax2 {
  width: 60vw;
  height: 60vw;
  bottom: 0;
  left: 0;
  z-index: 3;
  transform: translate(-60vw, 30vw);
  opacity: 0;
  clip-path: polygon(0 0%, 0% 100%, 100% 100%);
  background: -moz-linear-gradient(45deg, rgba(197, 191, 38, 0.8) 10%, rgba(247, 143, 27, 0.3) 50%);
  background: -webkit-linear-gradient(
    45deg,
    rgba(197, 191, 38, 0.8) 10%,
    rgba(247, 143, 27, 0.3) 50%
  );
  background: linear-gradient(45deg, rgba(197, 191, 38, 0.8) 10%, rgba(247, 143, 27, 0.3) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c5bf26', endColorstr='#30f78f1b', GradientType=1);
  position: absolute;
}

.gradother1 {
  height: 70vw;
  width: 70vw;
  z-index: 3;
  transform: translate(30vw, -40vw);
  right: 0;
  top: 0;
  opacity: 0;
  clip-path: polygon(0 0, 45% 0, 100% 55%, 100% 100%);
  background: -moz-linear-gradient(
    -45deg,
    rgba(27, 208, 247, 0.5) 0%,
    rgba(255, 166, 50, 0.2) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(27, 208, 247, 0.5) 0%,
    rgba(255, 166, 50, 0.2) 100%
  );
  background: linear-gradient(135deg, rgba(27, 208, 247, 0.5) 0%, rgba(255, 166, 50, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#501bd0f7', endColorstr='#99ffa632', GradientType=1);
}

.gradother2 {
  width: 60vw;
  height: 60vw;
  bottom: 0;
  right: 0;
  z-index: 3;
  transform: translate(40vw, 40vw);
  opacity: 0;
  clip-path: polygon(100% 2%, 0% 100%, 100% 100%);
  background: -moz-linear-gradient(-45deg, rgba(255, 186, 0, 0.3) 10%, rgba(120, 38, 197, 0.8) 90%);
  background: -webkit-linear-gradient(
    -45deg,
    rgba(255, 186, 0, 0.3) 10%,
    rgba(120, 38, 197, 0.8) 90%
  );
  background: linear-gradient(135deg, rgba(255, 186, 0, 0.3) 10%, rgba(120, 38, 197, 0.8) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30ffba00', endColorstr='#807826c5', GradientType=1);
}

.waxlog.gradwax1 {
  transform: translate(-50vw, 0vw);
  opacity: 0.7;
}

.waxlog.gradwax2 {
  transform: translate(0vw, 0vw);
  opacity: 0.3;
}

.otherlog.gradother1 {
  transform: translate(25vw, 0vw);
  opacity: 0.5;
}

.otherlog.gradother2 {
  transform: translate(0vw, 0vw);
  opacity: 0.3;
}

.waxlog.isdashboard.gradwax1 {
  transform: translate(-75vw, 0vw);
  opacity: 0.7;
}

.waxlog.isdashboard.gradwax2 {
  transform: translate(-30vw, 0vw);
  opacity: 0.3;
}

.otherlog.isdashboard.gradother1 {
  transform: translate(50vw, 0vw);
  opacity: 0.7;
}

.otherlog.isdashboard.gradother2 {
  transform: translate(30vw, 0vw);
  opacity: 0.3;
}

.gradients {
  position: absolute;
  z-index: 3;
  opacity: 0.3;
  -webkit-transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
  -moz-transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
  -ms-transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
  transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-text {
  font-size: 22px;
  letter-spacing: 5px;
  animation: flash infinite 2s linear;
}
